import './App.css';
function App() {

  return (
    <div className="App">
        <div className="content">
          <span className="bold"> Argia</span> (Basque) : La lumière, l'intelligence // Être artiste, c'est une grande joie,
          mais aussi une lourde peine.

          Être profondément inadaptés à une société matérialiste qui marche parfois <span className="boldblue" >sur la tête.</span> Quand les uns parlent de
          rentabilité, les autres de grève, eux se préoccupent <span className="bold">de couleurs et de lignes.</span> Au terme de crise, ils substituent celui de
          grâce. Au chahut médiatique ils répondent par de fines mélodies.

          <span className="boldblue"> La beauté est le langage secret de la vie.</span> Les fleurs sont l'héritage sublime de centaines de millions d'années
          d'aventures. L'éveil et le coucher du soleil pourraient être aussi insignifiants qu'ils sont réguliers, mais ils sont indescriptiblement beaux,
          et <span className="boldred">cela change tout.</span> Il suffit de s'asseoir devant l'océan pour connaître le divin.

          Les artistes sont sur Terre pour nous rappeler que nous avons <span className="boldblue">le droit et le devoir de rêver.</span> Le mystère
          de la vie est insondable, c'est là son plus grand présent. Mais l'art nous permet de le ressentir, de communier avec
          l'immensité et <span className="boldred">le permanent.</span> Hors des dogmes, loin des affirmations insipides, l'art s'épanouit
          <span className="bold"> dans le monde infini des questions.</span> Nous avons le devoir de rêver. Le devoir de prendre le
          temps. Sortir un moment de la frénésie des affaires, respirer, <span className="boldblue">prendre conscience</span> du miracle de l'existence.

          L'art nous invite à vivre. Le bonheur n'a pas de marque, la joie n'a pas de prix. L'émerveillement est et restera toujours gratuit.

          <span className="boldred"> Vivre avec l'art.</span> C'est ici notre proposition.

          Témoin muet d'une intransigeante quête de vérité, la danse du pinceau sur la toile est une oasis <span className="bold">de subtilité,
            de nuance, de courage, de paix</span>.

          Car il faut bien du courage pour oser répondre au tumulte de la machine industrielle par un silencieux coup de brosse.

          Assumer d'être hors norme. <span className="boldred">Assumer d'être incompris.</span> Peindre, comme danser, est un geste alchimique.

          <span className="bold"> L'art ne sert à rien, mais il est essentiel.</span>
        </div>
    </div>
  );
}

export default App;
